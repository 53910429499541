var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"items":_vm.transactions,"fields":[
      {
        key: 'id',
        label: 'Id',
        sorter: false,
        filter: false,
      },
      {
        key: 'amount',
        label: 'Amount',
        sorter: false,
        filter: false,
      },
      {
        key: 'createdAt',
        label: 'Created At',
        sorter: false,
        filter: false,
      },
      {
        key: 'status',
        label: 'Status',
        sorter: false,
        filter: false,
      },
      {
        key: 'name',
        label: 'Name',
        sorter: false,
        filter: false,
      },
      {
        key: 'actions',
        label: 'Aktionen',
        sorter: false,
        filter: false,
      } ],"table-filter-value":_vm.tableFilterValue,"sorter":{ external: true, resetable: true },"sorter-value":_vm.sorterValue,"hover":"","loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('FormatedTimestamp',{attrs:{"date":item.createdAt}})],1)]}},{key:"status",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"amount",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.amount))+" ")])]}},{key:"actions",fn:function(ref){return [_c('td',[_c('CDropdown',{staticClass:"m-2",attrs:{"color":"primary","toggler-text":""}})],1)]}}])}),(_vm.pages > 1)?_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'AdminControlTransaction' }}},[_vm._v(" "+_vm._s(_vm.$t('pages.admin.AdminTransactionIndex.ControlTransactionLink'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }