













































































import Vue, { VueConstructor } from 'vue';
import AdminPaymentGatewayService from '@/services/admin/AdminPaymentGatewayService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';

class TransactionDto {
  amount!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { DataTable, FormatedTimestamp },
  mixins: [FormaterMixin],
  data() {
    return {
      transactions: [] as TransactionDto[],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'id', asc: true },
      currentPage: 1,
      loading: false,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminTransactionIndex.meta.title').toString(),
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminTransactionIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      AdminPaymentGatewayService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.transactions = res.value.transactions.items;
        this.pages = res.value.transactions.totalPages;
      });
    },
  },
});
